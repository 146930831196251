export const IconHome = () => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth=".6"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.55 2.532a2.25 2.25 0 0 1 2.9 0l6.75 5.692c.507.428.8 1.057.8 1.72v9.803a1.75 1.75 0 0 1-1.75 1.75h-3.5a1.75 1.75 0 0 1-1.75-1.75v-5.5a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25v5.5a1.75 1.75 0 0 1-1.75 1.75h-3.5A1.75 1.75 0 0 1 3 19.747V9.944c0-.663.293-1.292.8-1.72l6.75-5.692Zm1.933 1.147a.75.75 0 0 0-.966 0L4.767 9.37a.75.75 0 0 0-.267.573v9.803c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25v-5.5c0-.967.784-1.75 1.75-1.75h3.5c.966 0 1.75.783 1.75 1.75v5.5c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25V9.944a.75.75 0 0 0-.267-.573l-6.75-5.692Z"
      fill="currentColor"
    />
  </svg>
)
export const IconWeb = () => (
  <svg
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="2" y1="12" x2="22" y2="12"></line>
    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
  </svg>
)
export const IconImage = () => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth=".6"
    stroke="currentColor">
    <path
      d="M18 11C18 9.067 19.567 7.5 21.5 7.5C23.433 7.5 25 9.067 25 11C25 12.933 23.433 14.5 21.5 14.5C19.567 14.5 18 12.933 18 11ZM21.5 9.5C20.6716 9.5 20 10.1716 20 11C20 11.8284 20.6716 12.5 21.5 12.5C22.3284 12.5 23 11.8284 23 11C23 10.1716 22.3284 9.5 21.5 9.5ZM3 7.5C3 5.01472 5.01472 3 7.5 3H24.5C26.9853 3 29 5.01472 29 7.5V24.5C29 26.9853 26.9853 29 24.5 29H7.5C5.01472 29 3 26.9853 3 24.5V7.5ZM7.5 5C6.11929 5 5 6.11929 5 7.5V24.5C5 24.7891 5.04909 25.0668 5.13938 25.3252L14.2323 16.2322C15.2086 15.2559 16.7915 15.2559 17.7678 16.2322L26.8607 25.325C26.9509 25.0667 27 24.7891 27 24.5V7.5C27 6.11929 25.8807 5 24.5 5H7.5ZM25.4994 26.7922L16.3536 17.6464C16.1584 17.4512 15.8418 17.4512 15.6465 17.6464L6.50069 26.7923C6.80674 26.9259 7.14471 27 7.5 27H24.5C24.8553 27 25.1933 26.9259 25.4994 26.7922Z"
      fill="currentColor"
    />
  </svg>
)
export const IconVideo = () => (
  //   <svg
  //     width="100%"
  //     height="100%"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z"
  //       stroke="currentColor"
  //       strokeWidth="2.2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconMusic = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18V6.35537C9 5.87383 9 5.63306 9.0876 5.43778C9.16482 5.26565 9.28917 5.11887 9.44627 5.0144C9.62449 4.89588 9.86198 4.8563 10.337 4.77714L19.137 3.31047C19.7779 3.20364 20.0984 3.15023 20.3482 3.243C20.5674 3.32441 20.7511 3.48005 20.8674 3.68286C21 3.91398 21 4.23889 21 4.8887V16M9 18C9 19.6568 7.65685 21 6 21C4.34315 21 3 19.6568 3 18C3 16.3431 4.34315 15 6 15C7.65685 15 9 16.3431 9 18ZM21 16C21 17.6568 19.6569 19 18 19C16.3431 19 15 17.6568 15 16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconSocial = () => (
  //   <svg
  //     width="100%"
  //     height="100%"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M18 15.8369C19.4559 16.5683 20.7041 17.742 21.6152 19.2096C21.7956 19.5003 21.8858 19.6456 21.917 19.8468C21.9804 20.2558 21.7008 20.7585 21.3199 20.9204C21.1325 21 20.9216 21 20.5 21M16 11.5322C17.4817 10.7959 18.5 9.26686 18.5 7.5C18.5 5.73314 17.4817 4.20411 16 3.46776M14 7.5C14 9.98528 11.9852 12 9.49996 12C7.01468 12 4.99996 9.98528 4.99996 7.5C4.99996 5.01472 7.01468 3 9.49996 3C11.9852 3 14 5.01472 14 7.5ZM2.55919 18.9383C4.1535 16.5446 6.66933 15 9.49996 15C12.3306 15 14.8464 16.5446 16.4407 18.9383C16.79 19.4628 16.9646 19.725 16.9445 20.0599C16.9289 20.3207 16.7579 20.64 16.5495 20.7976C16.2819 21 15.9138 21 15.1776 21H3.82232C3.08613 21 2.71804 21 2.4504 20.7976C2.24201 20.64 2.07105 20.3207 2.05539 20.0599C2.03529 19.725 2.20992 19.4628 2.55919 18.9383Z"
  //       stroke="currentColor"
  //       strokeWidth="2.1"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2.2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round">
    <circle cx="12" cy="12" r="4"></circle>
    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
  </svg>
)
export const IconAI = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    strokeWidth=".1"
    stroke="currentColor"
    fill="currentColor">
    <path
      fillRule="evenodd"
      d="m8 12.386 1.364-3.022L12.386 8 9.364 6.636 8 3.614 6.636 6.636 3.613 8l3.023 1.364zm-2.372-2.014L1.796 8.643c-.617-.206-.617-1.08 0-1.286l3.832-1.729 1.729-3.831c.206-.618 1.08-.618 1.286 0l1.729 3.831 3.831 1.73c.618.205.618 1.08 0 1.285l-3.831 1.729-1.73 3.832c-.205.617-1.08.617-1.285 0z"
      clipRule="evenodd"
    />
  </svg>
)
export const IconSparkle2 = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth=".3"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M8.664 15.735c.245.173.537.265.836.264v-.004a1.442 1.442 0 0 0 1.327-.872l.613-1.864a2.872 2.872 0 0 1 1.817-1.812l1.778-.578a1.443 1.443 0 0 0-.052-2.74l-1.755-.57a2.876 2.876 0 0 1-1.822-1.823l-.578-1.777a1.446 1.446 0 0 0-2.732.022l-.583 1.792a2.877 2.877 0 0 1-1.77 1.786l-1.777.571a1.444 1.444 0 0 0 .017 2.734l1.754.569a2.887 2.887 0 0 1 1.822 1.826l.578 1.775c.099.283.283.528.527.7Zm-.374-4.25a4.054 4.054 0 0 0-.363-.413h.003a4.394 4.394 0 0 0-1.72-1.063l-1.6-.508 1.611-.524a4.4 4.4 0 0 0 1.69-1.065 4.448 4.448 0 0 0 1.041-1.708l.515-1.582.516 1.587a4.374 4.374 0 0 0 2.781 2.773l1.62.522-1.59.515a4.379 4.379 0 0 0-2.774 2.775l-.515 1.582-.515-1.585a4.368 4.368 0 0 0-.7-1.306Zm8.041 9.297a1.123 1.123 0 0 1-.41-.549l-.328-1.007a1.293 1.293 0 0 0-.821-.823l-.991-.323A1.148 1.148 0 0 1 13 16.997a1.143 1.143 0 0 1 .771-1.08l1.006-.326a1.3 1.3 0 0 0 .8-.819l.324-.992a1.143 1.143 0 0 1 2.157-.021l.329 1.014a1.3 1.3 0 0 0 .82.816l.992.323a1.141 1.141 0 0 1 .039 2.165l-1.014.329a1.3 1.3 0 0 0-.818.822l-.322.989c-.078.23-.226.43-.425.57a1.14 1.14 0 0 1-1.328-.005Zm-1.03-3.783A2.789 2.789 0 0 1 17 18.708a2.794 2.794 0 0 1 1.7-1.7 2.813 2.813 0 0 1-1.718-1.708A2.806 2.806 0 0 1 15.3 17Z" />
  </svg>
)
export const IconMetaverse = () => (
  <svg fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M8 6V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h1.381c2.203 0 4.146 1.451 4.412 3.638q.033.27.062.553A2 2 0 0 1 23 12v2a2 2 0 0 1-1.145 1.809q-.03.283-.062.553C21.527 18.549 19.584 20 17.381 20h-1.145a3 3 0 0 1-2.408-1.212l-1.03-1.387a.994.994 0 0 0-1.596 0l-1.03 1.387A3 3 0 0 1 7.764 20H6.618c-2.202 0-4.145-1.451-4.411-3.638q-.033-.27-.062-.553A2 2 0 0 1 1 14v-2a2 2 0 0 1 1.145-1.809q.03-.283.062-.553C2.473 7.451 4.416 6 6.618 6zm2-1h4v1h-4zM6.618 8c-1.373 0-2.303.867-2.426 1.88A26 26 0 0 0 4 13c0 1.143.08 2.202.192 3.12C4.315 17.133 5.245 18 6.618 18h1.146a1 1 0 0 0 .802-.404l1.03-1.387a2.994 2.994 0 0 1 4.808 0l1.03 1.387a1 1 0 0 0 .802.404h1.145c1.374 0 2.304-.867 2.427-1.88.112-.918.192-1.977.192-3.12s-.08-2.202-.192-3.12C19.685 8.867 18.755 8 17.381 8z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
)
export const IconShopping = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M 2.7368404,20.526319 V 7.7894746 H 21.26316 V 20.526319 a 2.3157899,2.3157899 0 0 1 -2.31579,2.315789 H 5.0526302 A 2.3157899,2.3157899 0 0 1 2.7368404,20.526319"
      fill="none"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.2"
      d="M 8.5263153,11.26316 V 6.6315796 A 3.4736848,3.4736848 0 0 1 12,3.1578948 v 0 a 3.4736848,3.4736848 0 0 1 3.473685,3.4736848 V 11.26316"
      fill="none"
    />
  </svg>

  // <svg
  //   fill="currentColor"
  //   viewBox="0 0 24 24"
  //   stroke="currentColor"
  //   strokeWidth=".15">
  //   <path
  //     fillRule="evenodd"
  //     d="M10 5a2 2 0 1 1 4 0v1h-4zM8 8v2a1 1 0 1 0 2 0V8h4v2a1 1 0 1 0 2 0V8h.624a1 1 0 0 1 .973.771l.34 1.442A40 40 0 0 1 19 19.375V20a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-.625c0-3.085.357-6.16 1.063-9.162l.34-1.442A1 1 0 0 1 7.376 8zm0-2V5a4 4 0 1 1 8 0v1h.624a3 3 0 0 1 2.92 2.313l.34 1.442A42 42 0 0 1 21 19.375V20a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-.625a42 42 0 0 1 1.116-9.62l.34-1.442A3 3 0 0 1 7.376 6z"
  //     clipRule="evenodd"
  //   />
  // </svg>
)
export const IconLocal = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.2"
    stroke="currentColor">
    <path
      d="M14 2.25C19.3848 2.25 23.75 6.61522 23.75 12C23.75 16.1196 20.8554 20.6093 15.1402 25.5179C14.4844 26.0811 13.5156 26.0811 12.8572 25.5156L12.4794 25.1881C7.01712 20.4089 4.25 16.0278 4.25 12C4.25 6.61522 8.61522 2.25 14 2.25ZM14 3.75C9.44365 3.75 5.75 7.44365 5.75 12C5.75 15.5021 8.2984 19.5368 13.4645 24.057L13.8371 24.3799C13.9308 24.4604 14.0692 24.4604 14.1629 24.3799C19.5789 19.7283 22.25 15.5853 22.25 12C22.25 7.44365 18.5563 3.75 14 3.75ZM14 8.25C16.0711 8.25 17.75 9.92893 17.75 12C17.75 14.0711 16.0711 15.75 14 15.75C11.9289 15.75 10.25 14.0711 10.25 12C10.25 9.92893 11.9289 8.25 14 8.25ZM14 9.75C12.7574 9.75 11.75 10.7574 11.75 12C11.75 13.2426 12.7574 14.25 14 14.25C15.2426 14.25 16.25 13.2426 16.25 12C16.25 10.7574 15.2426 9.75 14 9.75Z"
      fill="currentColor"
    />
  </svg>
)
export const IconCode = () => (
  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
  //   <path
  //     fill="currentColor"
  //     fillRule="evenodd"
  //     d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zm1.293 4.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-2 2a1 1 0 0 1-1.414-1.414L8.586 12l-1.293-1.293a1 1 0 0 1 0-1.414M12 14a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1"
  //     clipRule="evenodd"
  //   />
  // </svg>

  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth=".8"
    stroke="currentColor">
    <path
      d="M8.06562 18.9434L14.5656 4.44339C14.7351 4.06542 15.1788 3.89637 15.5568 4.0658C15.9033 4.22112 16.0742 4.60695 15.9698 4.96131L15.9344 5.05698L9.43438 19.557C9.26495 19.935 8.82118 20.104 8.44321 19.9346C8.09673 19.7793 7.92581 19.3934 8.03024 19.0391L8.06562 18.9434L14.5656 4.44339L8.06562 18.9434ZM2.21967 11.4699L6.46967 7.21986C6.76256 6.92696 7.23744 6.92696 7.53033 7.21986C7.7966 7.48612 7.8208 7.90279 7.60295 8.1964L7.53033 8.28052L3.81066 12.0002L7.53033 15.7199C7.82322 16.0127 7.82322 16.4876 7.53033 16.7805C7.26406 17.0468 6.8474 17.071 6.55379 16.8531L6.46967 16.7805L2.21967 12.5305C1.9534 12.2642 1.9292 11.8476 2.14705 11.554L2.21967 11.4699L6.46967 7.21986L2.21967 11.4699ZM16.4697 7.21986C16.7359 6.95359 17.1526 6.92938 17.4462 7.14724L17.5303 7.21986L21.7803 11.4699C22.0466 11.7361 22.0708 12.1528 21.8529 12.4464L21.7803 12.5305L17.5303 16.7805C17.2374 17.0734 16.7626 17.0734 16.4697 16.7805C16.2034 16.5142 16.1792 16.0976 16.3971 15.804L16.4697 15.7199L20.1893 12.0002L16.4697 8.28052C16.1768 7.98762 16.1768 7.51275 16.4697 7.21986Z"
      fill="currentColor"></path>
  </svg>
)
export const IconAcademic = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 13.49007,4.4716995 a 1.0820024,1.0820024 0 0 0 -0.98354,0 L 2.1647506,9.7486257 1.1801284,7.8204971 11.524072,2.5435712 a 3.2460074,3.2460074 0 0 1 2.949538,0 l 10.343944,5.2769259 a 2.1423648,2.1423648 0 0 1 1.165317,2.1856459 v 6.253973 a 1.082003,1.082003 0 1 1 -2.164006,0 v -4.094297 l -2.199711,1.076592 v 4.429718 a 3.2460074,3.2460074 0 0 1 -1.7074,2.85865 l -5.374306,2.892193 a 3.2460074,3.2460074 0 0 1 -3.077215,0 L 6.0859275,20.530779 A 3.2460074,3.2460074 0 0 1 4.3785276,17.672129 V 13.242411 L 1.2115065,11.691902 C -0.38985714,10.90745 -0.40716918,8.6309169 1.1801284,7.8204971 L 2.1647506,9.7486257 12.52276,14.822135 a 1.0820024,1.0820024 0 0 0 0.952162,0 L 23.818865,9.7562002 V 9.7410552 Z M 6.5436145,14.302774 v 3.369355 a 1.0820024,1.0820024 0 0 0 0.5680513,0.952163 l 5.3743062,2.893274 a 1.0820024,1.0820024 0 0 0 1.025738,0 l 5.374306,-2.892192 a 1.0820024,1.0820024 0 0 0 0.569134,-0.952163 v -3.371519 l -5.028065,2.462638 a 3.2460074,3.2460074 0 0 1 -2.856487,0 z"
    />
  </svg>
)
export const IconJob = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2.1"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round">
    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
  </svg>
)
export const IconGames = () => (
  // <svg
  //   fill="currentColor"
  //   strokeWidth=".6"
  //   stroke="currentColor"
  //   viewBox="0 0 24 24"
  //   xmlns="http://www.w3.org/2000/svg">
  //   <path d="M14.998 5a7 7 0 0 1 .24 13.996l-.24.004H9.002a7 7 0 0 1-.24-13.996L9.001 5h5.996Zm0 1.5H9.002a5.5 5.5 0 0 0-.221 10.996l.221.004h5.996a5.5 5.5 0 0 0 .221-10.996l-.221-.004ZM8 9a.75.75 0 0 1 .75.75v1.498h1.5a.75.75 0 0 1 0 1.5h-1.5v1.502a.75.75 0 0 1-1.5 0v-1.502h-1.5a.75.75 0 1 1 0-1.5h1.5V9.75A.75.75 0 0 1 8 9Zm6.75 3.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm2-3.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z" />
  // </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    fill="currentColor"
    strokeWidth="6"
    stroke="currentColor">
    <path d="M176 112h-24a8 8 0 010-16h24a8 8 0 010 16zm-72-16h-8v-8a8 8 0 00-16 0v8h-8a8 8 0 000 16h8v8a8 8 0 0016 0v-8h8a8 8 0 000-16zm137.48 104.65a36 36 0 01-54.94 4.81c-.12-.12-.24-.24-.35-.37L146.48 160h-37l-39.67 45.09-.35.37A36.08 36.08 0 0144 216a36 36 0 01-35.44-42.25.68.68 0 010-.14l16.37-84.09A59.88 59.88 0 0183.89 40H172a60.08 60.08 0 0159 49.25v.18l16.37 84.17a.68.68 0 010 .14 35.74 35.74 0 01-5.89 26.91zM172 144a44 44 0 000-88H83.89a43.9 43.9 0 00-43.21 36.37v.13L24.3 176.59A20 20 0 0058 194.3l41.92-47.59a8 8 0 016-2.71zm59.7 32.59l-8.74-45A60 60 0 01172 160h-4.2l30.2 34.31a20.09 20.09 0 0017.46 5.39 20 20 0 0016.23-23.11z" />
  </svg>
)
export const IconNews = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path
      d="M17.384 3c.975 0 1.77.787 1.77 1.762v13.333c0 .462.354.846.815.899l.107.006.109-.006a.915.915 0 0 0 .809-.794l.006-.105V8.19a1 1 0 0 1 2 0v9.905A2.914 2.914 0 0 1 20.077 21H3.538a2.55 2.55 0 0 1-1.644-.601l-.147-.135A2.52 2.52 0 0 1 1 18.476V4.762C1 3.787 1.794 3 2.77 3zm-.231 2H3v13.476c0 .11.035.216.1.304l.054.063c.101.1.24.157.384.157l13.761-.001-.026-.078a3 3 0 0 1-.115-.655l-.004-.17zM14 15.021a.979.979 0 1 1 0 1.958H6a.979.979 0 1 1 0-1.958zm0-8a.98.98 0 0 1 .979.979v4a.98.98 0 0 1-.979.979H6A.98.98 0 0 1 5.021 12V8A.98.98 0 0 1 6 7.021zm-.98 1.958H6.979v2.041h6.041z"
      stroke="currentColor"
      strokeWidth=".1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconLegal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path
      d="M11.997 1c-.36 0-.718.09-1.036.265a1 1 0 0 0-.085.052L2.798 6.87a1.7 1.7 0 0 0-.72.897c-.13.389-.098.79.049 1.134C2.42 9.592 3.128 10 3.9 10H5v6h-.5A2.5 2.5 0 0 0 2 18.5v1A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5v-1a2.5 2.5 0 0 0-2.5-2.5H19v-6h1.092c.773 0 1.48-.407 1.775-1.1.146-.344.178-.744.048-1.133a1.7 1.7 0 0 0-.72-.897l-8.078-5.553a1 1 0 0 0-.085-.052A2.15 2.15 0 0 0 11.997 1M4.684 8l7.263-4.992a.16.16 0 0 1 .1 0L19.308 8zM17 16v-6h-2v6zm-4 0v-6h-2v6zm-4 0v-6H7v6zm-5 2.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5z"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="currentColor"
      strokeWidth="0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconTorrent = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth=".5"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.25 20.5a.75.75 0 1 1 0 1.5l-13 .004a.75.75 0 1 1 0-1.5l13-.004ZM11.648 2.012l.102-.007a.75.75 0 0 1 .743.648l.007.102-.001 13.685 3.722-3.72a.75.75 0 0 1 .976-.073l.085.073a.75.75 0 0 1 .072.976l-.073.084-4.997 4.997a.75.75 0 0 1-.976.073l-.085-.073-5.003-4.996a.75.75 0 0 1 .976-1.134l.084.072 3.719 3.714L11 2.755a.75.75 0 0 1 .648-.743l.102-.007-.102.007Z"
      fill="currentColor"
    />
  </svg>
)
export const IconDarknet = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.50008V12.0001M12 12.0001L20.5 7.27779M12 12.0001L3.5 7.27779M12 12.0001V21.5001M20.5 16.7223L12.777 12.4318C12.4934 12.2742 12.3516 12.1954 12.2015 12.1645C12.0685 12.1372 11.9315 12.1372 11.7986 12.1645C11.6484 12.1954 11.5066 12.2742 11.223 12.4318L3.5 16.7223M21 16.0586V7.94153C21 7.59889 21 7.42757 20.9495 7.27477C20.9049 7.13959 20.8318 7.01551 20.7354 6.91082C20.6263 6.79248 20.4766 6.70928 20.177 6.54288L12.777 2.43177C12.4934 2.27421 12.3516 2.19543 12.2015 2.16454C12.0685 2.13721 11.9315 2.13721 11.7986 2.16454C11.6484 2.19543 11.5066 2.27421 11.223 2.43177L3.82297 6.54288C3.52345 6.70928 3.37369 6.79248 3.26463 6.91082C3.16816 7.01551 3.09515 7.13959 3.05048 7.27477C3 7.42757 3 7.59889 3 7.94153V16.0586C3 16.4013 3 16.5726 3.05048 16.7254C3.09515 16.8606 3.16816 16.9847 3.26463 17.0893C3.37369 17.2077 3.52345 17.2909 3.82297 17.4573L11.223 21.5684C11.5066 21.726 11.6484 21.8047 11.7986 21.8356C11.9315 21.863 12.0685 21.863 12.2015 21.8356C12.3516 21.8047 12.4934 21.726 12.777 21.5684L20.177 17.4573C20.4766 17.2909 20.6263 17.2077 20.7354 17.0893C20.8318 16.9847 20.9049 16.8606 20.9495 16.7254C21 16.5726 21 16.4013 21 16.0586Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconSettings = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    strokeWidth=".3"
    stroke="currentColor"
    viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.568 3.5a1 1 0 0 0-.863.494l-.811 1.381A3 3 0 0 1 7.33 6.856l-1.596.013a1 1 0 0 0-.858.501l-.44.761a1 1 0 0 0-.003.992l.792 1.4a3 3 0 0 1 0 2.954l-.792 1.4a1 1 0 0 0 .004.992l.439.76a1 1 0 0 0 .858.502l1.596.013a3 3 0 0 1 2.564 1.48l.811 1.382a1 1 0 0 0 .863.494h.87a1 1 0 0 0 .862-.494l.812-1.381a3 3 0 0 1 2.563-1.481l1.596-.013a1 1 0 0 0 .859-.501l.439-.761a1 1 0 0 0 .004-.992l-.793-1.4a3 3 0 0 1 0-2.953l.793-1.401a1 1 0 0 0-.004-.992l-.439-.76a1 1 0 0 0-.859-.502l-1.596-.013a3 3 0 0 1-2.563-1.48L13.3 3.993a1 1 0 0 0-.862-.494zM8.98 2.981A3 3 0 0 1 11.568 1.5h.87a3 3 0 0 1 2.588 1.481l.81 1.382a1 1 0 0 0 .855.494l1.597.013a3 3 0 0 1 2.575 1.502l.44.76a3 3 0 0 1 .011 2.975l-.792 1.4a1 1 0 0 0 0 .985l.792 1.401a3 3 0 0 1-.012 2.974l-.439.761a3 3 0 0 1-2.575 1.503l-1.597.012a1 1 0 0 0-.854.494l-.811 1.382a3 3 0 0 1-2.588 1.481h-.87a3 3 0 0 1-2.588-1.481l-.811-1.382a1 1 0 0 0-.855-.494l-1.596-.012a3 3 0 0 1-2.576-1.503l-.439-.76a3 3 0 0 1-.012-2.975l.793-1.4a1 1 0 0 0 0-.985l-.793-1.4a3 3 0 0 1 .012-2.975l.44-.761A3 3 0 0 1 5.717 4.87l1.596-.013a1 1 0 0 0 .855-.494z"
      clipRule="evenodd"></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.003 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M8.502 12a3.5 3.5 0 1 1 7 .001 3.5 3.5 0 0 1-7-.001"
      clipRule="evenodd"></path>
  </svg>
)
export const IconSend = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="m 14.619135,3.585383 a 2.0003082,2.0003082 0 0 1 2.828081,0 l 9.000512,9.000508 a 2.0003082,2.0003082 0 0 1 -2.828082,2.828086 L 18.033486,9.8278111 V 27.001059 a 2.0003084,2.0003084 0 0 1 -4.000616,0 V 9.8278111 L 8.4467091,15.413977 A 2.0003082,2.0003082 0 1 1 5.6186244,12.585891 Z"
    />
  </svg>
)
export const IconShield = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 11.4999L11 13.4999L15.5 8.99987M20 11.9999C20 16.9083 14.646 20.4783 12.698 21.6147C12.4766 21.7439 12.3659 21.8085 12.2097 21.842C12.0884 21.868 11.9116 21.868 11.7903 21.842C11.6341 21.8085 11.5234 21.7439 11.302 21.6147C9.35396 20.4783 4 16.9083 4 11.9999V7.21747C4 6.41796 4 6.0182 4.13076 5.67457C4.24627 5.37101 4.43398 5.10015 4.67766 4.8854C4.9535 4.64231 5.3278 4.50195 6.0764 4.22122L11.4382 2.21054C11.6461 2.13258 11.75 2.0936 11.857 2.07815C11.9518 2.06444 12.0482 2.06444 12.143 2.07815C12.25 2.0936 12.3539 2.13258 12.5618 2.21054L17.9236 4.22122C18.6722 4.50195 19.0465 4.64231 19.3223 4.8854C19.566 5.10015 19.7537 5.37101 19.8692 5.67457C20 6.0182 20 6.41796 20 7.21747V11.9999Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconMic = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 32 32"
  //   fill="currentColor">
  //   <path
  //     d="m16,20.21c2.8,0,5.05-2.26,5.05-5.05V5.05c0-2.8-2.26-5.05-5.05-5.05s-5.05,2.26-5.05,5.05v10.11c0,2.8,2.26,5.05,5.05,5.05Zm-1.68-15.16c0-.93.76-1.68,1.68-1.68s1.68.76,1.68,1.68v10.11c0,.94-.74,1.68-1.68,1.68s-1.68-.76-1.68-1.68V5.05Zm11.94,10.11h0c.87,0,1.54.78,1.41,1.64-.77,4.99-4.99,8.93-9.98,9.68v4.1c0,.79-.64,1.43-1.43,1.43h-.51c-.79,0-1.43-.64-1.43-1.43v-4.1c-4.99-.75-9.21-4.69-9.98-9.68-.13-.86.54-1.64,1.41-1.64h0c.71,0,1.31.53,1.41,1.23.63,4.39,4.58,7.36,8.84,7.36s8.21-2.97,8.84-7.36c.1-.7.7-1.23,1.41-1.23Z"
  //     stroke="currentColor"
  //     strokeWidth=".2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>

  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 2 16 20">
  //   <g clip-path="url(#a)">
  //     <path
  //       fill-rule="evenodd"
  //       d="M12 2c-1.792 0-3.396 1.148-3.707 2.913A18 18 0 0 0 8 8c0 1.075.128 2.15.293 3.087C8.603 12.852 10.208 14 12 14s3.396-1.148 3.707-2.913C15.872 10.15 16 9.075 16 8s-.128-2.15-.293-3.087C15.397 3.148 13.792 2 12 2m2 6c0-.925-.111-1.879-.263-2.74C13.622 4.602 12.984 4 12 4s-1.622.603-1.737 1.26A16 16 0 0 0 10 8c0 .925.111 1.879.263 2.74.115.657.753 1.26 1.737 1.26s1.622-.603 1.737-1.26C13.889 9.88 14 8.926 14 8"
  //       clip-rule="evenodd"
  //     />
  //     <path d="M12 16c2.753 0 5.17-1.52 5.725-3.918.17-.736.275-1.45.275-2.082a1 1 0 1 1 2 0c0 .835-.135 1.709-.326 2.534-.746 3.22-3.639 5.076-6.674 5.41V20h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-2.055c-3.035-.335-5.928-2.191-6.674-5.412C4.136 11.71 4 10.835 4 10a1 1 0 0 1 2 0c0 .633.104 1.346.275 2.082C6.83 14.48 9.247 16 12 16" />
  //   </g>
  // </svg>

  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    version="1.1"
    id="svg2"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg">
    <defs id="defs2" />
    <g transform="matrix(1.2,0,0,1.2,-2.4,-2.4)" id="g2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m 8,6 c 0,-2.20914 1.79086,-4 4,-4 2.2091,0 4,1.79086 4,4 v 5 c 0,2.2091 -1.7909,4 -4,4 -2.20914,0 -4,-1.7909 -4,-4 z m 4,-2 c -1.1046,0 -2,0.89543 -2,2 v 5 c 0,1.1046 0.8954,2 2,2 1.1046,0 2,-0.8954 2,-2 V 6 C 14,4.89543 13.1046,4 12,4 Z"
        id="path1"
      />
      <path
        d="m 5,9 c 0.55228,0 1,0.44772 1,1 v 1 c 0,3.3137 2.68629,6 6,6 3.3137,0 6,-2.6863 6,-6 v -1 c 0,-0.55228 0.4477,-1 1,-1 0.5523,0 1,0.44772 1,1 v 1 c 0,4.0803 -3.0547,7.4471 -7.0019,7.9383 C 12.9994,18.9587 13,18.9793 13,19 v 2 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 v -2 c 0,-0.0207 6e-4,-0.0413 0.0019,-0.0617 C 7.05466,18.4471 4,15.0803 4,11 V 10 C 4,9.44772 4.44772,9 5,9 Z"
        id="path2"
      />
    </g>
  </svg>
)
export const IconTrending = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.2"
    strokeLinecap="round"
    strokeLinejoin="round">
    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
    <polyline points="17 6 23 6 23 12"></polyline>
  </svg>
)
export const IconStories = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth=".2">
    <path d="M4.774 1.378a.75.75 0 01.538-.915 6.75 6.75 0 013.375 0 .75.75 0 11-.375 1.452 5.257 5.257 0 00-2.625 0 .75.75 0 01-.913-.537zm-2.59.893A6.756 6.756 0 00.496 5.193a.75.75 0 101.446.4 5.25 5.25 0 011.312-2.272.75.75 0 10-1.07-1.05zM1.94 8.405a.75.75 0 00-1.445.4 6.75 6.75 0 001.688 2.925.75.75 0 001.066-1.052 5.25 5.25 0 01-1.309-2.273zm6.371 3.679a5.25 5.25 0 01-2.625 0 .75.75 0 10-.375 1.452 6.75 6.75 0 003.375 0 .75.75 0 10-.375-1.452zm4.67-4.201a.75.75 0 00-.92.523 5.25 5.25 0 01-1.312 2.272.75.75 0 001.07 1.051 6.763 6.763 0 001.688-2.923.75.75 0 00-.526-.923zm-.923-2.288a.75.75 0 001.445-.402 6.75 6.75 0 00-1.687-2.923.75.75 0 10-1.067 1.051 5.25 5.25 0 011.309 2.274z"></path>
  </svg>
)
export const IconClose = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2.5"
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
)
export const IconLang = () => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth=".1"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m9.563 7.505.056.117 5.307 13.005a1 1 0 0 1-1.802.86l-.05-.105L11.693 18H5.407l-1.49 3.407a1 1 0 0 1-1.208.555l-.11-.04a1 1 0 0 1-.556-1.208l.04-.11L7.778 7.6c.336-.77 1.394-.795 1.786-.094ZM19 2a1 1 0 0 1 .993.883L20 3v4h1a1 1 0 0 1 .993.883L22 8a1 1 0 0 1-.883.993L21 9h-1v7a1 1 0 0 1-.883.993L19 17a1 1 0 0 1-.993-.883L18 16V3a1 1 0 0 1 1-1ZM8.66 10.567 6.282 16h4.595L8.66 10.567ZM11 2h5a1 1 0 0 1 .993.883L17 3v2.975a4 4 0 0 1-4 4 1 1 0 1 1 0-2 2 2 0 0 0 1.995-1.85l.005-.15V4h-4a1 1 0 0 1-.117-1.993L11 2h5-5Z"
      fill="currentColor"
    />
  </svg>
)
export const IconUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1m0 16c-1.806 0-3.52.994-4.664 2.698A8.95 8.95 0 0 0 12 21a8.96 8.96 0 0 0 4.664-1.301C15.52 17.994 13.806 17 12 17m0-14a9 9 0 0 0-6.25 15.476C7.253 16.304 9.54 15 12 15s4.747 1.304 6.25 3.475A9 9 0 0 0 12 3m0 3a4 4 0 1 1 0 8 4 4 0 0 1 0-8m0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
      stroke="currentColor"
      strokeWidth=".1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconAccessibility = () => (
  <svg
    viewBox="0 0 23.999999 23.999999"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.3540617,0,0,1.3540617,-4.2487394,-4.0932779)">
      <path
        d="m 14,7 c 0,1.10457 -0.8954,2 -2,2 -1.1046,0 -2,-0.89543 -2,-2 0,-1.10457 0.8954,-2 2,-2 1.1046,0 2,0.89543 2,2 z"
        stroke="currentColor"
        strokeWidth="1.7"
      />
      <path
        d="m 18,10 c 0,0 -3.5373,1.5 -6,1.5 C 9.53727,11.5 6,10 6,10"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="m 12,12 v 1.4522 m 0,0 c 0,0.5753 0.1654,1.1384 0.4765,1.6223 L 15,19 m -3,-5.5478 c 0,0.5753 -0.1654,1.1384 -0.4765,1.6223 L 9,19"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </g>
  </svg>
)
export const IconOpenSource = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        stroke="currentColor"
        strokeWidth=".2"
        strokeLinecap="round"
        d="M12 2c5.523 0 10 4.477 10 10 0 4.4-2.841 8.136-6.789 9.473l-.226.074-2.904-7.55C13.15 13.95 14 13.054 14 12c0-1.105-.895-2-2-2s-2 .895-2 2c0 1.077.851 1.955 1.917 1.998l-2.903 7.549-.225-.074C4.84 20.136 2 16.4 2 12 2 6.477 6.477 2 12 2zm0 2c-4.418 0-8 3.582-8 8 0 2.92 1.564 5.475 3.901 6.872l1.48-3.849C8.534 14.29 8 13.207 8 12c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.207-.535 2.29-1.38 3.023.565 1.474 1.059 2.757 1.479 3.85C18.435 17.475 20 14.92 20 12c0-4.418-3.582-8-8-8z"
      />
    </g>
  </svg>
)
export const IconFileSearch = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1525 10.8995L12.1369 19.9151C10.0866 21.9653 6.7625 21.9653 4.71225 19.9151C2.662 17.8648 2.662 14.5407 4.71225 12.4904L13.7279 3.47483C15.0947 2.108 17.3108 2.108 18.6776 3.47483C20.0444 4.84167 20.0444 7.05775 18.6776 8.42458L10.0156 17.0866C9.33213 17.7701 8.22409 17.7701 7.54068 17.0866C6.85726 16.4032 6.85726 15.2952 7.54068 14.6118L15.1421 7.01037"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconLanguage = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1"
    stroke="currentColor">
    <path
      d="M12.7502 2C12.336 2 12.0002 2.33579 12.0002 2.75C12.0002 3.16421 12.336 3.5 12.7502 3.5H18.5002V6.30519C18.5002 8.0543 16.7512 9.5 14.7502 9.5C14.336 9.5 14.0002 9.83579 14.0002 10.25C14.0002 10.6642 14.336 11 14.7502 11C17.3137 11 20.0002 9.12567 20.0002 6.30519V2.75C20.0002 2.33579 19.6644 2 19.2502 2H12.7502ZM10.693 7.46282C10.5769 7.18263 10.3034 6.99997 10.0001 7C9.69682 7.00003 9.42339 7.18274 9.3073 7.46295L2.0573 24.963C1.89877 25.3457 2.08047 25.7844 2.46314 25.943C2.84582 26.1015 3.28455 25.9198 3.44309 25.5371L5.53071 20.498H14.4725L16.5613 25.5373C16.7199 25.9199 17.1587 26.1015 17.5413 25.9429C17.9239 25.7843 18.1056 25.3455 17.947 24.9629L15.7434 19.6469C15.7257 19.5153 15.6739 19.3946 15.5971 19.2938L10.693 7.46282ZM13.8507 18.998H6.15214L10.0004 9.70911L13.8507 18.998ZM22.2518 2C22.666 2 23.0018 2.33579 23.0018 2.75V8.5H25.2502C25.6644 8.5 26.0002 8.83579 26.0002 9.25C26.0002 9.66421 25.6644 10 25.2502 10H23.0018V19.2495C23.0018 19.6637 22.666 19.9995 22.2518 19.9995C21.8376 19.9995 21.5018 19.6637 21.5018 19.2495V9.29918C21.5007 9.28292 21.5002 9.26652 21.5002 9.25C21.5002 9.23348 21.5007 9.21708 21.5018 9.20082V2.75C21.5018 2.33579 21.8376 2 22.2518 2Z"
      fill="currentColor"
    />
  </svg>
)
export const IconSearch = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.2"
    stroke="currentColor">
    <path
      d="M13.5 3C7.70101 3 3 7.70101 3 13.5C3 19.299 7.70101 24 13.5 24C16.0394 24 18.3683 23.0985 20.1841 21.5981L27.293 28.7071C27.6836 29.0976 28.3167 29.0976 28.7073 28.7071C29.0978 28.3166 29.0978 27.6834 28.7073 27.2929L21.5983 20.1839C23.0986 18.3682 24 16.0393 24 13.5C24 7.70101 19.299 3 13.5 3ZM5 13.5C5 8.80558 8.80558 5 13.5 5C18.1944 5 22 8.80558 22 13.5C22 18.1944 18.1944 22 13.5 22C8.80558 22 5 18.1944 5 13.5Z"
      fill="currentColor"
    />
  </svg>
)
export const IconCheckOk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.4"
    strokeLinecap="round"
    strokeLinejoin="round">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
)
export const IconFeedback = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.59961 19.9203L7.12357 18.7012L7.13478 18.6926C7.45249 18.4384 7.61281 18.3101 7.79168 18.2188C7.95216 18.1368 8.12328 18.0771 8.2998 18.0408C8.49877 18 8.70603 18 9.12207 18H17.8031C18.921 18 19.4806 18 19.908 17.7822C20.2843 17.5905 20.5905 17.2842 20.7822 16.9079C21 16.4805 21 15.9215 21 14.8036V7.19691C21 6.07899 21 5.5192 20.7822 5.0918C20.5905 4.71547 20.2837 4.40973 19.9074 4.21799C19.4796 4 18.9203 4 17.8002 4H6.2002C5.08009 4 4.51962 4 4.0918 4.21799C3.71547 4.40973 3.40973 4.71547 3.21799 5.0918C3 5.51962 3 6.08009 3 7.2002V18.6712C3 19.7369 3 20.2696 3.21846 20.5433C3.40845 20.7813 3.69644 20.9198 4.00098 20.9195C4.35115 20.9191 4.76744 20.5861 5.59961 19.9203Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconLocationGps = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.6"
    stroke="currentColor">
    <path
      d="M28.8612 5.72272C29.4833 4.10524 27.894 2.51596 26.2766 3.13807L4.28218 11.5975C2.47024 12.2943 2.61372 14.9042 4.49115 15.3983L13.514 17.7727C13.8626 17.8645 14.1348 18.1367 14.2266 18.4853L16.601 27.5081C17.0951 29.3856 19.7049 29.529 20.4018 27.7171L28.8612 5.72272ZM26.9945 5.00476L18.5351 26.9991L16.1607 17.9763C15.8855 16.9305 15.0688 16.1138 14.023 15.8386L5.00014 13.4641L26.9945 5.00476Z"
      fill="currentColor"
    />
  </svg>
)
export const IconHeart = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1"
    stroke="currentColor">
    <path
      d="M3.38563 7.13082C6.35842 2.96011 12.5531 2.95562 15.532 7.12201L15.9964 7.77164L16.4137 7.17921C19.3686 2.98397 25.5742 2.94256 28.5848 7.098C30.6822 9.99286 30.4377 13.9672 28.0014 16.5832L16.7343 28.6811C16.545 28.8844 16.2797 28.9997 16.0019 28.9995C15.7241 28.9994 15.4589 28.8836 15.2698 28.6801L3.99445 16.5399C1.58467 13.9453 1.33031 10.0143 3.38563 7.13082ZM13.905 8.28522C11.7246 5.23554 7.19026 5.23883 5.01426 8.29167C3.50982 10.4023 3.696 13.2797 5.4599 15.1789L16.0036 26.5312L26.5378 15.2201C28.3226 13.3037 28.5017 10.3921 26.9652 8.2714C24.7597 5.22719 20.2136 5.25752 18.0488 8.3309L16.8201 10.0754C16.6335 10.3403 16.3301 10.4984 16.0061 10.4995C15.682 10.5007 15.3775 10.3448 15.1891 10.0812L13.905 8.28522Z"
      fill="currentColor"
    />
  </svg>
)
export const IconStar = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1"
    stroke="currentColor">
    <path
      d="M12.7013 3.90984C13.2332 2.83213 14.7699 2.83214 15.3018 3.90984L17.9928 9.36237L24.01 10.2367C25.1993 10.4095 25.6742 11.8711 24.8136 12.71L20.4595 16.9542L21.4874 22.9471C21.6905 24.1316 20.4473 25.0349 19.3835 24.4756L14.0015 21.6462L8.61958 24.4756C7.55582 25.0349 6.31254 24.1316 6.5157 22.9471L7.54357 16.9542L3.18947 12.71C2.32887 11.8711 2.80376 10.4095 3.99308 10.2367L10.0103 9.36237L12.7013 3.90984ZM14.0015 4.66454L11.3438 10.0498C11.1326 10.4777 10.7243 10.7744 10.252 10.843L4.30903 11.7066L8.60941 15.8984C8.95115 16.2315 9.1071 16.7115 9.02642 17.1818L8.01124 23.1008L13.3268 20.3062C13.7492 20.0842 14.2539 20.0842 14.6763 20.3062L19.9918 23.1008L18.9767 17.1818C18.896 16.7115 19.0519 16.2315 19.3937 15.8984L23.6941 11.7066L17.7511 10.843C17.2788 10.7744 16.8705 10.4777 16.6593 10.0498L14.0015 4.66454Z"
      fill="currentColor"
    />
  </svg>
)
export const IconMoon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 15.8442C20.6866 16.4382 19.2286 16.7688 17.6935 16.7688C11.9153 16.7688 7.23116 12.0847 7.23116 6.30654C7.23116 4.77135 7.5618 3.3134 8.15577 2C4.52576 3.64163 2 7.2947 2 11.5377C2 17.3159 6.68414 22 12.4623 22C16.7053 22 20.3584 19.4742 22 15.8442Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconSun = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2V4M12 20V22M4 12H2M6.31412 6.31412L4.8999 4.8999M17.6859 6.31412L19.1001 4.8999M6.31412 17.69L4.8999 19.1042M17.6859 17.69L19.1001 19.1042M22 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconList = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth=".8"
    stroke="currentColor"
    fill="currentColor">
    <path d="M12 10.5C12 11.3284 11.3284 12 10.5 12C9.67157 12 9 11.3284 9 10.5C9 9.67157 9.67157 9 10.5 9C11.3284 9 12 9.67157 12 10.5ZM12 16C12 16.8284 11.3284 17.5 10.5 17.5C9.67157 17.5 9 16.8284 9 16C9 15.1716 9.67157 14.5 10.5 14.5C11.3284 14.5 12 15.1716 12 16ZM10.5 23C11.3284 23 12 22.3284 12 21.5C12 20.6716 11.3284 20 10.5 20C9.67157 20 9 20.6716 9 21.5C9 22.3284 9.67157 23 10.5 23ZM14 10.5C14 9.94772 14.4477 9.5 15 9.5H22C22.5523 9.5 23 9.94772 23 10.5C23 11.0523 22.5523 11.5 22 11.5H15C14.4477 11.5 14 11.0523 14 10.5ZM15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17H22C22.5523 17 23 16.5523 23 16C23 15.4477 22.5523 15 22 15H15ZM14 21.5C14 20.9477 14.4477 20.5 15 20.5H22C22.5523 20.5 23 20.9477 23 21.5C23 22.0523 22.5523 22.5 22 22.5H15C14.4477 22.5 14 22.0523 14 21.5ZM7.5 3C5.01472 3 3 5.01472 3 7.5V24.5C3 26.9853 5.01472 29 7.5 29H24.5C26.9853 29 29 26.9853 29 24.5V7.5C29 5.01472 26.9853 3 24.5 3H7.5ZM5 7.5C5 6.11929 6.11929 5 7.5 5H24.5C25.8807 5 27 6.11929 27 7.5V24.5C27 25.8807 25.8807 27 24.5 27H7.5C6.11929 27 5 25.8807 5 24.5V7.5Z" />
  </svg>
)
export const IconCircles = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth=".4"
    stroke="currentColor"
    fill="currentColor">
    <path
      d="M13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8ZM11.125 8C11.125 9.72589 9.72589 11.125 8 11.125C6.27411 11.125 4.875 9.72589 4.875 8C4.875 6.27411 6.27411 4.875 8 4.875C9.72589 4.875 11.125 6.27411 11.125 8ZM25 8C25 5.23858 22.7614 3 20 3C17.2386 3 15 5.23858 15 8C15 10.7614 17.2386 13 20 13C22.7614 13 25 10.7614 25 8ZM23.125 8C23.125 9.72589 21.7259 11.125 20 11.125C18.2741 11.125 16.875 9.72589 16.875 8C16.875 6.27411 18.2741 4.875 20 4.875C21.7259 4.875 23.125 6.27411 23.125 8ZM8 25C5.23858 25 3 22.7614 3 20C3 17.2386 5.23858 15 8 15C10.7614 15 13 17.2386 13 20C13 22.7614 10.7614 25 8 25ZM8 23.125C9.72589 23.125 11.125 21.7259 11.125 20C11.125 18.2741 9.72589 16.875 8 16.875C6.27411 16.875 4.875 18.2741 4.875 20C4.875 21.7259 6.27411 23.125 8 23.125ZM25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25C22.7614 25 25 22.7614 25 20ZM23.125 20C23.125 21.7259 21.7259 23.125 20 23.125C18.2741 23.125 16.875 21.7259 16.875 20C16.875 18.2741 18.2741 16.875 20 16.875C21.7259 16.875 23.125 18.2741 23.125 20Z"
      fill="currentColor"
    />
  </svg>
)
export const IconFinance = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 7H4.6C4.03995 7 3.75992 7 3.54601 7.10899C3.35785 7.20487 3.20487 7.35785 3.10899 7.54601C3 7.75992 3 8.03995 3 8.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H9M9 21H15M9 21L9 4.6C9 4.03995 9 3.75992 9.10899 3.54601C9.20487 3.35785 9.35785 3.20487 9.54601 3.10899C9.75992 3 10.0399 3 10.6 3L13.4 3C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V21M15 11H19.4C19.9601 11 20.2401 11 20.454 11.109C20.6422 11.2049 20.7951 11.3578 20.891 11.546C21 11.7599 21 12.0399 21 12.6V19.4C21 19.9601 21 20.2401 20.891 20.454C20.7951 20.6422 20.6422 20.7951 20.454 20.891C20.2401 21 19.9601 21 19.4 21H15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconApps = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth=".8"
    stroke="currentColor">
    <path
      d="M24.8079 2.95386C23.5387 1.68465 21.4809 1.68465 20.2117 2.95386L16.999 6.16657C16.9547 4.41019 15.517 2.99997 13.75 2.99997H6.25C4.45507 2.99997 3 4.45504 3 6.24997V25.75C3 27.5449 4.45507 29 6.25 29L25.75 29C27.5449 29 29 27.5449 29 25.75V18.25C29 16.4858 27.5944 15.0499 25.842 15.0012L29.0505 11.7927C30.3197 10.5235 30.3197 8.4657 29.0505 7.1965L24.8079 2.95386ZM19.1763 15H17V12.8237L19.1763 15ZM21.6259 4.36807C22.114 3.87991 22.9055 3.87991 23.3936 4.36807L27.6363 8.61071C28.1244 9.09887 28.1244 9.89032 27.6363 10.3785L23.3936 14.6211C22.9055 15.1093 22.114 15.1093 21.6259 14.6211L17.3832 10.3785C16.8951 9.89032 16.8951 9.09887 17.3832 8.61071L21.6259 4.36807ZM15 6.24997V15H5V6.24997C5 5.55961 5.55964 4.99997 6.25 4.99997H13.75C14.4404 4.99997 15 5.55961 15 6.24997ZM5 25.75V17H15V27H6.25C5.55964 27 5 26.4403 5 25.75ZM17 17H25.75C26.4404 17 27 17.5596 27 18.25V25.75C27 26.4403 26.4404 27 25.75 27H17V17Z"
      fill="currentColor"
    />
  </svg>
)
export const IconEye = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.2"
    stroke="currentColor">
    <path
      d="M28.0335 17.29C28.1635 17.72 28.5634 18 28.9933 18V17.99C29.0933 17.99 29.1832 17.98 29.2832 17.95C29.8131 17.79 30.113 17.24 29.9531 16.71C29.9231 16.61 26.6138 6 15.9961 6C5.3784 6 2.06911 16.61 2.03912 16.72C1.87915 17.25 2.17909 17.8 2.70897 17.96C3.23886 18.12 3.79874 17.82 3.9587 17.29C4.06868 16.91 6.88807 8 15.9961 8C25.1005 8 27.9114 16.9033 28.0335 17.29ZM12 18C12 15.7909 13.7909 14 16 14C18.2091 14 20 15.7909 20 18C20 20.2091 18.2091 22 16 22C13.7909 22 12 20.2091 12 18ZM16 12C12.6863 12 10 14.6863 10 18C10 21.3137 12.6863 24 16 24C19.3137 24 22 21.3137 22 18C22 14.6863 19.3137 12 16 12Z"
      fill="currentColor"
    />
  </svg>
)
export const IconText = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth=".85"
    stroke="currentColor"
    fill="currentColor">
    <path d="m2.75,16.25h12.5c.41,0,.75.34.75.75,0,.38-.28.69-.65.74h-.1s-12.5,0-12.5,0c-.41,0-.75-.34-.75-.75,0-.38.28-.69.65-.74h.1s12.5,0,12.5,0H2.75Z" />
    <path d="m2.75,11.25h18.5c.41,0,.75.34.75.75,0,.38-.28.69-.65.74h-.1s-18.5,0-18.5,0c-.41,0-.75-.34-.75-.75,0-.38.28-.69.65-.74h.1s18.5,0,18.5,0H2.75Z" />
    <path d="m2.75,6.25h18.5c.41,0,.75.34.75.75,0,.38-.28.69-.65.74h-.1s-18.5,0-18.5,0c-.41,0-.75-.34-.75-.75,0-.38.28-.69.65-.74h.1s18.5,0,18.5,0H2.75Z" />
  </svg>
)
export const IconCarbon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.07598 7.48282L7.36402 10.5457C7.58715 10.705 7.69872 10.7847 7.81548 10.8031C7.91821 10.8192 8.02343 10.8029 8.11648 10.7565C8.22223 10.7037 8.30449 10.594 8.46901 10.3747L9.37511 9.16652C9.42164 9.10448 9.4449 9.07347 9.47224 9.04671C9.49652 9.02295 9.52315 9.00173 9.55173 8.98338C9.58392 8.9627 9.61935 8.94696 9.6902 8.91546L13.5588 7.19609C13.7192 7.12482 13.7993 7.08918 13.8598 7.03352C13.9133 6.9843 13.9554 6.924 13.9832 6.85684C14.0146 6.78091 14.0204 6.69336 14.0321 6.51826L14.3154 2.2694M13.5 13.5L16.116 14.6211C16.4195 14.7512 16.5713 14.8163 16.6517 14.9243C16.7222 15.0191 16.7569 15.1358 16.7496 15.2537C16.7413 15.3881 16.6497 15.5255 16.4665 15.8002L15.2375 17.6438C15.1507 17.774 15.1072 17.8391 15.0499 17.8863C14.9991 17.928 14.9406 17.9593 14.8777 17.9784C14.8067 18 14.7284 18 14.5719 18H12.5766C12.3693 18 12.2656 18 12.1774 17.9653C12.0995 17.9347 12.0305 17.885 11.9768 17.8208C11.916 17.7481 11.8832 17.6497 11.8177 17.453L11.1048 15.3144C11.0661 15.1983 11.0468 15.1403 11.0417 15.0814C11.0372 15.0291 11.0409 14.9764 11.0528 14.9253C11.0662 14.8677 11.0935 14.813 11.1482 14.7036L11.6897 13.6206C11.7997 13.4005 11.8547 13.2905 11.9395 13.2222C12.0141 13.162 12.1046 13.1246 12.1999 13.1143C12.3081 13.1027 12.4248 13.1416 12.6582 13.2194L13.5 13.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconMore = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round">
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="12" cy="5" r="1"></circle>
    <circle cx="12" cy="19" r="1"></circle>
  </svg>
)
export const IconInfo = () => (
  <svg
    width="24"
    height="24"
    fill="currentColor"
    strokeWidth=".7"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999Zm0 1.5a8.502 8.502 0 1 0 0 17.003A8.502 8.502 0 0 0 12 3.5Zm-.004 7a.75.75 0 0 1 .744.648l.007.102.003 5.502a.75.75 0 0 1-1.493.102l-.007-.101-.003-5.502a.75.75 0 0 1 .75-.75ZM12 7.003a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997Z" />
  </svg>
)
export const IconThemeSystem = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5V18.5Z"
      stroke="currentColor"
      strokeWidth="2.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconChevronDown = () => (
  <svg
    width="24"
    height="24"
    fill="currentColor"
    strokeWidth=".5"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.293 8.293a1 1 0 0 1 1.414 0L12 14.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414Z" />
  </svg>
)
export const IconArrowRight = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.704 4.284a1 1 0 1 0-1.403 1.424L17.67 11H4a1 1 0 1 0 0 2h13.665L12.3 18.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823Z"
      fill="currentColor"
    />
  </svg>
)
export const IconCategories = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    strokeWidth=".075"
    stroke="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M2.5 6.75a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M17.25 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M13 6.75a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M6.75 15a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M2.5 17.25a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M17.25 15a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M13 17.25a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0"
    />
  </svg>
)
export const IconArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    fill="none"
    viewBox="0 0 10 16"
    strokeWidth=".2"
    stroke="currentColor">
    <path
      fill="currentColor"
      d="M.21 5.352a.714.714 0 0 1 1.01 0L5 9.132l3.78-3.78a.714.714 0 0 1 1.01 1.01l-4.285 4.286a.714.714 0 0 1-1.01 0L.209 6.362a.714.714 0 0 1 0-1.01"
    />
  </svg>
)
export const IconNew = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2.75"
    viewBox="0 0 24 24">
    <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
  </svg>
)
export const IconMenu = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 8.5H21M3 15.5H21"
      stroke="currentColor"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconArrowExternal = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 18L18 6M18 6H10M18 6V14"
      stroke="currentColor"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconX = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.44 80"
    fill="currentColor">
    <path d="m60.99,5h11.87l-25.95,29.65,30.52,40.35h-23.89l-18.71-24.47-21.42,24.47H1.53l27.75-31.71L0,5h24.5l16.92,22.37L60.99,5Zm-4.17,62.89h6.58L20.93,11.74h-7.07l42.95,56.16h0Z" />
  </svg>
)
export const IconBluesky = () => (
  <svg fill="currentColor" viewBox="0 0 64 57">
    <path d="M13.873 3.805C21.21 9.332 29.103 20.537 32 26.55v15.882c0-.338-.13.044-.41.867-1.512 4.456-7.418 21.847-20.923 7.944-7.111-7.32-3.819-14.64 9.125-16.85-7.405 1.264-15.73-.825-18.014-9.015C1.12 23.022 0 8.51 0 6.55 0-3.268 8.579-.182 13.873 3.805ZM50.127 3.805C42.79 9.332 34.897 20.537 32 26.55v15.882c0-.338.13.044.41.867 1.512 4.456 7.418 21.847 20.923 7.944 7.111-7.32 3.819-14.64-9.125-16.85 7.405 1.264 15.73-.825 18.014-9.015C62.88 23.022 64 8.51 64 6.55c0-9.818-8.578-6.732-13.873-2.745Z"></path>
  </svg>
)
export const IconGitHub = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path
      className="svg"
      d="M12,.37A11.93,11.93,0,0,0,8.15,23.62c.59.08.81-.29.81-.58V21c-3.3.74-4-1.61-4-1.61A3,3,0,0,0,3.6,17.68c-1-.73.08-.73.08-.73A2.52,2.52,0,0,1,5.51,18.2a2.54,2.54,0,0,0,3.44,1H9a2.57,2.57,0,0,1,.74-1.61c-2.65-.3-5.43-1.33-5.43-6A4.51,4.51,0,0,1,5.51,8.44a4.17,4.17,0,0,1,.15-3.16S6.68,5,9,6.53a10.53,10.53,0,0,1,3-.37,13.33,13.33,0,0,1,3,.37c2.27-1.54,3.3-1.25,3.3-1.25a4.58,4.58,0,0,1,.14,3.16,4.71,4.71,0,0,1,1.25,3.23c0,4.62-2.79,5.57-5.43,5.86a2.84,2.84,0,0,1,.81,2.21V23c0,.29.22.66.81.58A12,12,0,0,0,24,12.25,12,12,0,0,0,12,.37Z"
    />
  </svg>
)
export const IconInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </svg>
)
export const IconDiscord = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path
      className="svg"
      d="M20.35,4.43a20.11,20.11,0,0,0-5-1.53,12.8,12.8,0,0,0-.63,1.29,18.77,18.77,0,0,0-5.5,0c-.17-.4-.42-.91-.63-1.29a19.6,19.6,0,0,0-5,1.53A20,20,0,0,0,.1,18.05,20.27,20.27,0,0,0,6.18,21.1,16,16,0,0,0,7.48,19a13.32,13.32,0,0,1-2.05-1c.17-.13.34-.26.49-.39a14.34,14.34,0,0,0,12.15,0l.49.39a13.32,13.32,0,0,1-2,1,16,16,0,0,0,1.3,2.1,20.15,20.15,0,0,0,6.08-3.05A19.91,19.91,0,0,0,20.35,4.43ZM8,15.3a2.29,2.29,0,0,1-2.16-2.4A2.28,2.28,0,0,1,8,10.5a2.27,2.27,0,0,1,2.16,2.4A2.29,2.29,0,0,1,8,15.3Zm8,0a2.29,2.29,0,0,1-2.16-2.4A2.28,2.28,0,0,1,16,10.5a2.27,2.27,0,0,1,2.16,2.4A2.28,2.28,0,0,1,16,15.3Z"
    />
  </svg>
)
export const IconLinkedIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M1.6.06H22.35A1.81,1.81,0,0,1,24,1.83V22.06a1.82,1.82,0,0,1-1.62,1.88H1.62A1.76,1.76,0,0,1,0,22.35V1.66A1.75,1.75,0,0,1,1.6.06M4.84,3.39A2.07,2.07,0,0,0,3.29,5.74a2.07,2.07,0,0,0,4.09-.63A2.09,2.09,0,0,0,4.84,3.39m7.91,7.22c0-.54,0-1.07,0-1.61-1.14,0-2.28,0-3.41,0q0,5.73,0,11.44c1.18,0,2.37,0,3.55,0V14.89a3.93,3.93,0,0,1,.54-2.38,2.06,2.06,0,0,1,1.93-.63,1.46,1.46,0,0,1,1.16.77,4.56,4.56,0,0,1,.34,2.13v5.65c1.19,0,2.38,0,3.56,0V13.81a7.92,7.92,0,0,0-.51-3,3.14,3.14,0,0,0-1.53-1.64,5.23,5.23,0,0,0-2.68-.41,3.77,3.77,0,0,0-3,1.86M3.55,9q0,5.73,0,11.44c1.19,0,2.38,0,3.57,0V9Z"
    />
  </svg>
)
export const IconPatreon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle className="svg" cx="15.34" cy="9.11" r="8.66" />
    <rect className="svg" y="0.45" width="4.23" height="23.1" />
  </svg>
)
export const IconKofi = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      className="svg"
      d="m31.84,11.93c-1.03-5.45-6.48-6.12-6.48-6.12H.96C.16,5.81.06,6.87.06,6.87c0,0-.11,9.77-.03,15.76.22,3.23,3.45,3.56,3.45,3.56,0,0,11.02-.03,15.95-.06,3.25-.57,3.58-3.42,3.55-4.98,5.8.32,9.89-3.77,8.86-9.22Zm-14.75,4.68c-1.66,1.94-5.35,5.3-5.35,5.3,0,0-.16.16-.41.03-.1-.07-.14-.12-.14-.12-.59-.59-4.49-4.06-5.38-5.27-.95-1.29-1.39-3.6-.12-4.95,1.27-1.35,4.01-1.45,5.82.54,0,0,2.09-2.38,4.62-1.28,2.54,1.09,2.44,4.01.96,5.75Zm8.23.64c-1.24.15-2.24.04-2.24.04v-7.58h2.36s2.63.73,2.63,3.52c0,2.55-1.31,3.56-2.75,4.02Z"
    />
  </svg>
)
export const IconTwitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M13.25,3.58a5,5,0,0,1,7,.23A10.54,10.54,0,0,0,23.39,2.6a5.28,5.28,0,0,1-2.16,2.74A9.55,9.55,0,0,0,24,4.55v0a10.07,10.07,0,0,1-2.45,2.55,14.35,14.35,0,0,1-6.61,12.63,13.92,13.92,0,0,1-7.66,2,13.72,13.72,0,0,1-5.13-1.06A17.48,17.48,0,0,1,0,19.58H0a3.94,3.94,0,0,1,.68,0A10,10,0,0,0,7.3,17.51a4.82,4.82,0,0,1-2.58-.78,5,5,0,0,1-2-2.65A4.72,4.72,0,0,0,4.89,14,4.94,4.94,0,0,1,1,9.12a5.28,5.28,0,0,0,2.22.62,5,5,0,0,1-1.5-6.6A14,14,0,0,0,11.83,8.31,5,5,0,0,1,13.25,3.58Z" />
  </svg>
)

export const Icon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1"
    stroke="currentColor">
    <path
      d="M3 6.25C3 4.45507 4.45507 3 6.25 3H21.75C23.5449 3 25 4.45507 25 6.25V21.75C25 23.5449 23.5449 25 21.75 25H6.25C4.45507 25 3 23.5449 3 21.75V6.25ZM6.25 4.5C5.2835 4.5 4.5 5.2835 4.5 6.25V21.75C4.5 22.7165 5.2835 23.5 6.25 23.5H21.75C22.7165 23.5 23.5 22.7165 23.5 21.75V6.25C23.5 5.2835 22.7165 4.5 21.75 4.5H6.25Z"
      fill="currentColor"
    />
  </svg>
)
